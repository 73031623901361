import { createMemo, onMount } from 'solid-js';
import { Chart, Title, Tooltip, Legend, Colors } from 'chart.js';
import { Line } from 'solid-chartjs';

type Point = {
	label: string;
	value: number;
};

type Series = {
	label: string;
	points: Array<Point>;
	color: string;
};

type Props = {
	series: Array<Series>;
	height: number;
};

export function LineChart(props: Props) {
	onMount(() => {
		Chart.register(Title, Tooltip, Legend, Colors);
	});

	// Extract all unique labels from all series
	const xLabels = createMemo(() => {
		const labels = new Set<string>();
		props.series.forEach((series) => {
			series.points.forEach((point) => {
				labels.add(point.label);
			});
		});
		return Array.from(labels);
	});

	// Convert our data format to Chart.js format
	const chartData = createMemo(() => {
		return {
			labels: xLabels(),
			datasets: props.series.map((series) => {
				// Create a map of label -> value for this series
				const pointMap = new Map<string, number>();
				series.points.forEach((point) => {
					pointMap.set(point.label, point.value);
				});

				// Create an array of values in the same order as xLabels
				const values = xLabels().map((label) => pointMap.get(label) || 0);

				return {
					label: series.label,
					data: values,
					borderColor: series.color,
					backgroundColor: series.color,
					tension: 0.3,
					pointRadius: 3,
				};
			}),
		};
	});

	const chartOptions = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					usePointStyle: true,
				},
			},
			tooltip: {
				mode: 'index',
				intersect: false,
			},
		},
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					precision: 0,
				},
			},
		},
	};

	return (
		<div class="size-full">
			<Line data={chartData()} options={chartOptions} height={props.height} />
		</div>
	);
}
