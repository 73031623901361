import { ActivityIndicator, Heading, HorizontalRule, Section } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { cachedGet } from '@troon/api-client';
import { createAsync } from '@solidjs/router';
import { Suspense, Show } from 'solid-js';
import { Content } from '../../../../../components/main-content';
import { LineChart } from '../../../../../components/line-chart';
import type { RouteSectionProps } from '@solidjs/router';

export default function Analytics(props: RouteSectionProps) {
	const signups = createAsync(() => signupStats({ path: { facilityId: props.params.facility! } }));
	const rewards = createAsync(() => rewardsStats({ path: { facilityId: props.params.facility! } }));
	return (
		<Content>
			<Title>Analytics | Troon Operator</Title>
			<Heading as="h1">Analytics</Heading>

			<Heading as="h3">Troon Rewards Sign Ups</Heading>

			<Suspense fallback={<ActivityIndicator>Loading...</ActivityIndicator>}>
				<Show when={signups()?.data}>
					<div class="grid grid-cols-4 gap-4 xl:grid-cols-4 xl:gap-8">
						<AnalyticsCard title="Today" value={signups()!.data.summary.day} />
						<AnalyticsCard title="This Week" value={signups()!.data.summary.week} />
						<AnalyticsCard title="This Month" value={signups()!.data.summary.month} />
						<AnalyticsCard title="This Year" value={signups()!.data.summary.year} />
					</div>

					<Section appearance="contained">
						<LineChart
							series={[
								{
									label: 'This Year',
									points: signups()!.data.series.byMonth.current.map((day) => ({ label: day.date, value: day.count })),
									color: '#027f88',
								},
								{
									label: 'Last Year',
									points: signups()!.data.series.byMonth.previous.map((day) => ({ label: day.date, value: day.count })),
									color: '#767676',
								},
							]}
							height={300}
						/>
					</Section>
				</Show>
			</Suspense>

			<Heading as="h3">Troon Rewards Transactions</Heading>

			<Suspense fallback={<ActivityIndicator>Loading...</ActivityIndicator>}>
				<Show when={rewards()?.data}>
					<div class="grid grid-cols-4 gap-4 xl:grid-cols-4 xl:gap-8">
						<RewardsAnalyticsCard title="Today" value={rewards()!.data.summary.day} />
						<RewardsAnalyticsCard title="This Week" value={rewards()!.data.summary.week} />
						<RewardsAnalyticsCard title="This Month" value={rewards()!.data.summary.month} />
						<RewardsAnalyticsCard title="This Year" value={rewards()!.data.summary.year} />
					</div>

					<Section appearance="contained">
						<LineChart
							series={[
								{
									label: 'Rewards This Year',
									points: rewards()!.data.series.byMonth.current.map((day) => ({
										label: day.date,
										value: day.awardPoints,
									})),
									color: '#027f88',
								},
								{
									label: 'Redemptions This Year',
									points: rewards()!.data.series.byMonth.current.map((day) => ({
										label: day.date,
										value: day.redemptionPoints,
									})),
									color: '#67b2b8',
								},
								{
									label: 'Rewards Last Year',
									points: rewards()!.data.series.byMonth.previous.map((day) => ({
										label: day.date,
										value: day.awardPoints,
									})),
									color: '#767676',
								},
								{
									label: 'Redemptions Last Year',
									points: rewards()!.data.series.byMonth.previous.map((day) => ({
										label: day.date,
										value: day.redemptionPoints,
									})),
									color: '#b0b0b0',
								},
							]}
							height={300}
						/>
					</Section>
				</Show>
			</Suspense>
		</Content>
	);
}

function AnalyticsCard(props: {
	title: string;
	value: { current: { count: number; date: string }; previous: { count: number; date: string } };
}) {
	return (
		<Section appearance="contained" class="gap-2">
			<Heading as="h4">{props.title}</Heading>
			<span class="text-lg font-semibold">{props.value.current.count}</span>
			<HorizontalRule />
			<span class="text-base text-neutral-800">Last Year</span>
			<span class="text-base text-neutral-800">{props.value.previous.count}</span>
		</Section>
	);
}

function RewardsAnalyticsCard(props: {
	title: string;
	value: {
		current: { awardPoints: number; redemptionPoints: number; date: string };
		previous: { awardPoints: number; redemptionPoints: number; date: string };
	};
}) {
	return (
		<Section appearance="contained" class="gap-2">
			<Heading as="h4">{props.title}</Heading>
			<Heading class="text-neutral-800" as="h6">
				Rewards / Redemptions
			</Heading>
			<span class="text-lg font-semibold">
				{props.value.current.awardPoints} / {props.value.current.redemptionPoints}
			</span>
			<HorizontalRule />
			<span class="text-base text-neutral-800">Last Year</span>
			<span class="text-base text-neutral-800">
				{props.value.previous.awardPoints} / {props.value.previous.redemptionPoints}
			</span>
		</Section>
	);
}

const signupStats = cachedGet('/v0/operator/facilities/{facilityId}/signup-stats');
const rewardsStats = cachedGet('/v0/operator/facilities/{facilityId}/rewards-stats');
